<template>
  <div :class="isqpisboot ? 'wbobye zhadminadd': 'zhadminadd'">
    <div style="text-align: right;z-index: 2;" >
       <el-button  size="small" style="text-align: right;"  type="primary" @click="isqpisboot = !isqpisboot">{{isqpisboot ? $t('All.取消全屏'): $t('All.全屏')}}</el-button>
       <el-button size="small" type="primary"   @click="IQC_User(form,0)">{{this.$t('system.tijiao')}}</el-button>
       <el-button size="small" type="danger"   @click="IQC_User(form,7)">{{$t('All.紧急提交')}}</el-button>
       <el-button size="small" type="success"   @click="IQC_User(form,3)">{{this.$t('system.zancun')}}</el-button>
       <el-button size="small" type="primary"   @click="IsUpload(3,FileList3)">{{this.$t('system.fjian')}}</el-button>
     </div>
     <h2 style="text-align: center;">{{this.$t('system.company')}}</h2>
     <h3 style="text-align: center;">{{this.$t('system.jinliaojy')}}</h3>
     <h4 style="text-align: center;">{{this.$t('system.系统单号')}}：{{form.Numbers}}</h4>
     <h6 style="text-align: left; color: red;" v-if="Msgtieleng">{{this.$t('system.警告')}}：{{ Msgtieleng }}</h6>
     <p style="text-align: right;padding-right: 10px;">{{ $t('All.进料日期') }}
      <el-date-picker
          v-model="form.Inspection_No"
          type="date"
          :placeholder="$t('All.选择日期')"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd">
        </el-date-picker>
    </p>
     <div style=" padding: 10px; text-align: center; line-height: 2vw;">
       <table border="1"  align="center" cellpadding="10" style="table-layout: fixed; word-break: break-all; word-wrap: break-word;width: 100%;">
           <tr>
              <td  colspan="2">{{this.$t('IQC.检验基准表')}}</td>
              <td >{{this.$t('IQC.工程图面')}}</td>
              <td >{{this.$t('IQC.承认书')}}</td>
              <td  colspan="2">{{this.$t('IQC.品名')}}</td>
              <td  colspan="2">{{this.$t('IQC.料号')}}</td>
              <td  colspan="2">{{this.$t('IQC.订单号')}}</td>

           </tr>
           <tr>
            <td  colspan="2" class="tdd">
              <el-tooltip effect="dark" :content="form.Inspection_Table" placement="top-start">
                  <div style="overflow: hidden;white-space: nowrap;cursor: default;text-overflow: ellipsis;color: #006bda;">
                    <span @click="fileopen(form.Inspection_Tableurl)">{{ form.Inspection_Table }}</span>
                  </div>
                </el-tooltip>
            </td>
            <td class="tdd">
              <el-tooltip effect="dark" :content="form.Drawing_Surface" placement="top-start">
                  <div style="overflow: hidden;white-space: nowrap;cursor: default;text-overflow: ellipsis;color: #006bda;">
                    <span @click="fileopen(form.Drawing_Surfaceurl)">{{ form.Drawing_Surface }}</span>
                  </div>
                </el-tooltip>
            </td>
            <td class="tdd">
              <el-tooltip effect="dark" :content="form.Acknowledgment" placement="top-start">
                  <div style="overflow: hidden;white-space: nowrap;cursor: default;text-overflow: ellipsis;color: #006bda;">
                    <span @click="fileopen(form.Acknowledgmenturl)">{{ form.Acknowledgment }}</span>
                  </div>
                </el-tooltip>
            </td>
            <td  colspan="2" class="tdd">{{ form.Product_name }}</td>
            <td  colspan="2" class="tdd">{{ form.Item_No }}</td>
            <td  colspan="2" class="tdd">{{ form.Order_Number }}</td>
           </tr>
           <tr>
             <td colspan="2">{{$t('All.标准样品编号')  }}</td>
             <td >{{ $t('All.物料单位') }}</td>
             <td >{{this.$t('IQC.交货数')}}</td>
             <td >{{this.$t('IQC.入库数')}}</td>
             <td >{{this.$t('IQC.抽样数')}}</td>
             <td colspan="2">{{this.$t('IQC.抽样类型')}}</td>
             <td colspan="2">{{this.$t('IQC.供应商')}}</td>
           </tr>
           <tr>
             <td  colspan="2" class="tdd"><el-input v-model="form.Standard_sample"></el-input></td>
             <td class="tdd"> <!--<el-input v-model="form.Company"></el-input>-->
              <el-select v-model="form.Company" filterable allow-create :placeholder="$t('All.单位')" @change="chuangjcompany" :style="{width: '100%'}">
                <el-option label="G" value="G"></el-option>
                <el-option label="KG" value="KG"></el-option>
                <el-option label="PC" value="PC"></el-option>
                <el-option label="M" value="M"></el-option>
                <el-option label="KM" value="KM"></el-option>
              </el-select>
            </td>
             <td class="tdd"><el-input onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = value.slice(0, value.indexOf('.') + 5); }" v-model="form.Delivery"></el-input></td>
             <td class="tdd"><el-input onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = value.slice(0, value.indexOf('.') + 5); }" v-model="form.Warehousing"></el-input></td>
             <td class="tdd"><el-input v-model="form.Sampling"></el-input></td>
             <td colspan="2" class="tdd"><el-input v-model="form.Sampling_type"></el-input></td>
             <td colspan="2" class="tdd">{{ form.Supplier }}</td>
           </tr>
           <tr>
             <td colspan="10"><el-input disabled v-model="form.Standard"><template slot="prepend">{{this.$t('IQC.执行标准')}}</template></el-input></td>
           </tr>
           <tr>
             <td >{{this.$t('IQC.检验项目')}}</td>
             <td colspan="2">{{this.$t('IQC.检验标准')}}</td>
             <td colspan="1">{{ $t('All.检验工具') }}</td>
             <td colspan="2">{{ $t('All.判定基准') }}</td>
             <td colspan="1">{{this.$t('All.实验频率') }}</td>
             <td colspan="1">{{this.$t('IQC.判定')}}</td>
             <td colspan="2">{{this.$t('IQC.备注')}}/{{ $t('All.测试报告') }}</td>
           </tr>
           <tr v-for="(item, index) in form.Testlist" :key="'Testlist' + index">
             <td><el-input disabled v-model="item.Title"></el-input></td> <!--:rowspan="item.Index" v-if="(item.Index !== 0)"-->
             <td colspan="2">{{ item.Standard }}</td>
             <td colspan="1">{{ item.Tool }}</td>
             <td colspan="2">{{ item.Benchmark }}</td>
             <td colspan="1">{{$PublicJs.Frequencyfun(item.Frequency) }}</td>
             <td colspan="1">
                  <el-select :disabled="((item.Determinetype == 1))" v-model="item.Determine" :placeholder="item.Determinetype == 1?'实验室判定':$t('IQC.请判定')">
                      <el-option label="Pass" value="Pass"></el-option>
                      <el-option label="Fail" value="Fail"></el-option>
                      <el-option label="NA" value="NA"></el-option>
                  </el-select>
             </td>
             <td colspan="2">
              <el-input type="textarea" v-if="(item.Determinetype == 2)" :placeholder="$t('All.实验室上传检测报告')" :rows="3" v-model="item.Remarks"></el-input>
              <el-link @click="lookshiyan(item,index)" type="primary" v-if="item.Determinetype == 1 && item.Remarks.length > 0 && item.Remarks != ''" >{{item.Remarks}}</el-link>
              <el-button size="mini" v-if="item.Determinetype == 1 && !isdisabled && !item.Remarks" type="primary"  @click="Testbox(item, index)" >{{ $t('All.送测单') }}</el-button>
              <el-button style="margin-left: 0 !important;margin-top: 5px;" size="mini" v-show="item.Determinetype == 1 && (item.Remarks.length === 0 || item.Remarks == '')" type="warning"  @click="mzTestbox(item, index)" >{{ $t('All.免做单') }}</el-button>
            </td>
           </tr>
           <tr>
               <td class="tdd" rowspan="2">{{this.$t('IQC.规格要求')}}</td>
               <td class="tdd" colspan="2">{{this.$t('IQC.量测仪器编号')}}</td>
               <td colspan="7" class="tdd"><el-input v-model="form.Measuring"></el-input></td>
           </tr>
           <tr>
            <td colspan="1" class="tdd" >{{ $t('All.单位')}}/{{$t('All.标注')}}</td>
              <td colspan="5" class="tdd" >{{this.$t('IQC.尺寸检验记录')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.最大值')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.最小值')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.判定')}}</td>
           </tr>
           <tr v-for="(text, index) in form.NewIQCParcss" :key="index">
               <td class="tdd">{{ text.Specification }}</td>
               <td class="tdd">{{ text.Units_Dimensions }}</td>
               <td class="tdd"><el-input v-model="text.Text1" v-enter-next-input></el-input></td>
               <td class="tdd"><el-input v-model="text.Text2" v-enter-next-input></el-input></td>
               <td class="tdd"><el-input v-model="text.Text3" v-enter-next-input></el-input></td>
               <td class="tdd"><el-input v-model="text.Text4" v-enter-next-input></el-input></td>
               <td class="tdd"><el-input v-model="text.Text5" v-enter-next-input name="Text5" @change="Keyenter(text,index)"></el-input></td>
               <td class="tdd"><el-input v-model="text.Max"></el-input></td>
               <td class="tdd"><el-input v-model="text.Min"></el-input></td>
               <td class="tdd">
                <el-select v-model="text.Determine" :placeholder="$t('All.请选择')" @change="addlist(text,index,$event)">
                    <el-option label="Pass" value="Pass"></el-option>
                    <el-option label="Fail" value="Fail"></el-option>
                    <el-option :label="$t('All.判定')" :value="$t('All.判定')"></el-option>
                    <el-option :label="$t('All.增加')" :value="$t('All.增加')"></el-option>
                    <el-option :disabled="form.NewIQCParcss.length == 1" :label="$t('All.删除')" :value="$t('All.删除')"></el-option>
                </el-select>
          </td>
           </tr>
           <tr>
            <td colspan="10"><el-input type="textarea" disabled :rows="6" v-model="form.Beizhu"></el-input></td>
          </tr>
       </table>
       <el-row>
         <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('IQC.核准')}}</span></el-col>
         <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('IQC.检验员')}}: {{form.Inspector}}</span></el-col>
       </el-row>
       <el-row>
         <el-col :span="12" style="border: 0px solid transparent;"><h6></h6></el-col>
         <el-col :span="12" style="border: 0px solid transparent;padding-right: 20px;text-align: right;"><span style="font-size: 1.5vw;">NYP1001-37-K</span></el-col>
       </el-row>
     </div>
    <iqcUpload  v-if="isboll" :Number = "form.Numbers" :isdata="isdata" :Type = "Type" :FileList = "FileList" :FalseUpload="falseUpload" :Uphandsuccess="uphandsuccess"></iqcUpload>
  <el-dialog :title="this.$t('All.请选择对应供应商承认书')" :visible.sync="filedialogVisible" width="80%" append-to-body :before-close="handleClose">
    <el-table :data="filedata" v-show="filedata.length > 0" stripe style="width: 100%" @row-click="rowdblclick">
        <el-table-column prop="Filenumber" :label="this.$t('All.文件编号')"></el-table-column>
        <el-table-column prop="Filename" :label="this.$t('All.文件名称')"></el-table-column>
        <el-table-column prop="Version" :label="this.$t('All.版本')"></el-table-column>
        <el-table-column prop="Description" :label="this.$t('All.描述')"></el-table-column>
      </el-table>
    </el-dialog>
  <el-dialog
  :title="$t('All.请输入已做实验单号')"
  :visible.sync="yzdialogVisible"
  width="500px"
  :before-close="handleClose" append-to-body>
  {{$t('All.实验单号')}}：<el-input style="width: 300px;border: 1px solid #DCDFE6 !important;border-radius: 5px;" v-model="Mz_Numbers"></el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="yzdialogVisible = false">{{$t('All.取消')}}</el-button>
    <el-button type="primary" @click="Gettestbaogao()">{{ $t('All.确认') }}</el-button>
  </span>
</el-dialog>
  <NewiqcTestsheet v-if="Texttable" :formxin="form" :Test_Numbers="Test_Numbers" :Texttablebool="Texttablebool" :Test_Item="Test_Item" :Test_Index="Test_Index" :Test_Supplier="Test_Supplier" :Test_Product_name="Test_Product_name" :Texttablefunadd="Texttablefunadd"></NewiqcTestsheet>
  </div>
 </template>
<script>
import iqcUpload from './Upload/NewiqcUserUpload.vue'
import NewiqcTestsheet from './Upload/NewiqcTestsheet'
import { mapMutations } from 'vuex'
import Vue from 'vue'
// 自定义指令，实现按下enter后，光标自动跳转下一个输入框
Vue.directive('enterNextInput', {
  inserted: function (el) {
    el.addEventListener('keypress', function (e) {
      e = e || window.event
      const charcode = typeof e.charCode === 'number' ? e.charCode : e.keyCode
      if (charcode === 13) {
        try {
          var dom = document.getElementsByTagName('input')
          for (var i = 0; i < dom.length; i++) {
            if (dom[i] === document.activeElement) {
              if (dom[i].name === 'Text5') {
                dom[i + 4].focus()
              } else {
                dom[i + 1].focus()
              }
              return
            }
          }
        } catch (error) {

        }
      }
    })
  }
})
export default {
  data () {
    return {
      form: {
      // 系统单号
        Number: '',
        // 检验基准表
        Inspection_Table: '',
        // 工程图面
        Drawing_Surface: '',
        // 承认书
        Acknowledgment: '',
        // 品名
        Product_name: '',
        // 料号
        Item_No: '',
        // 供应商
        Supplier: '',
        // 标准样品编号
        Standard_sample: '',
        // 物料单位
        Company: '',
        // 交货数
        Delivery: '',
        // 入库数
        Warehousing: '',
        // 抽样数
        Sampling: '',
        // 抽样类型
        Sampling_type: '',
        // 订单号
        Order_Number: '',
        // 执行标准
        Standard: '',
        // 量测仪器编号
        Measuring: '',
        // 备注
        Beizhu: '備註:\n1、填寫承認書、工程圖面與進料檢驗基準表的編號同時，需將其文件版本記錄。\n2、参照承認書、工程圖面與進料檢驗基準表統計需管控的重點尺寸總項數並如實填寫，避免發生漏檢/漏記的現象。\n3、量測的尺寸規格按抽樣數10%的比例記錄；當記錄的尺寸規格個數大於10组數據時，則記錄前10PCS樣本的量測结果值。\n4、所有使用的儀器設備需填寫儀器編號,以便後續進行追溯。',
        // 录入人
        Enteredby: '',
        //
        Factory: '',
        //
        Testlist: [
          {
          //
            Number: '',
            //
            Index: 0,
            //
            Title: '',
            //
            Standard: '',
            //
            Tool: '',
            Frequency: 0,
            //
            Benchmark: '',
            Determinetype: '',
            //
            Determine: '',
            //
            Remarks: ''
          }
        ],
        NewIQCParcss: [
          {
          //
            Number: '',
            //
            Index: 0,
            //
            Specification: '',
            //
            Units_Dimensions: '',
            //
            Text1: '',
            Text2: '',
            Text3: '',
            Text4: '',
            Text5: '',
            //
            Max: '',
            //
            Min: '',
            //
            Determine: ''
          }
        ],
        // 系统单号
        Numbers: '',
        // 检验员
        Inspector: this.$store.state.Login.Username,
        // 状态
        State: 0,
        Inspection_No: ''
      },
      isqpisboot: false,
      FileList3: [],
      Type: 0,
      isboll: false,
      prinform: {
        Boxlist: [
          {
            Box: 0,
            Boxquantity: 0,
            Pass: 'Pass'
          }
        ],
        Month: this.$PublicJs.Dateselection(),
        Printer: this.$store.state.PrinterData[0],
        Date: new Date()
      },
      Index: '',
      Texttable: false,
      Test_Item: '',
      Test_Index: '',
      Test_Supplier: '',
      Test_Product_name: '',
      Test_Numbers: '',
      Mz_Numbers: '',
      Mz_Index: '',
      Msgtieleng: '',
      filedialogVisible: false,
      filedata: [],
      isdisabled: false,
      yzdialogVisible: false,
      Mz_day: '',
      isdata: false
    }
  },
  components: {
    iqcUpload,
    NewiqcTestsheet
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  created () {
    if (this.$store.state.UserData === null) {
      this.$message.success(this.$t('All.数据不存在'))
      this.$store.state.TabComponent = 'NewUseIQC'
      this.Addtab(this.$t('All.新IQC检验单'), 'NewUseIQC')
      this.RemoveTab(this.$t('All.新IQC检验单') + '-' + this.$store.state.UserData.Numbers)
    } else {
      this.isdata = false
      this.form = this.$store.state.UserData
      this.GetSdaten(this.form.Item_No)
    }
  },
  mounted () {
    this.IQCAdminGetAllFiles(this.form.Number)
    this.FileitemDrawing_number(this.form.Item_No, 1, this.form.Factory)
    this.Inspection_Table_number(this.form.Item_No, 4, this.form.Factory)
    this.Acknowledgment_number(this.form.Item_No, 0, this.form.Factory)
  },
  methods: {
    mzTestbox (item, index) {
      this.Mz_day = item.Frequency
      this.Mz_Index = index
      this.yzdialogVisible = true
    },
    Keyenter (item, index) {
      this.form.NewIQCParcss[index].Determine = ''
      const valany = []
      if (item.Text1 !== null && item.Text1 !== '' && item.Text1 !== undefined) {
        valany.push(parseFloat(item.Text1))
      }
      if (item.Text2 !== null && item.Text2 !== '' && item.Text2 !== undefined) {
        valany.push(parseFloat(item.Text2))
      }
      if (item.Text3 !== null && item.Text3 !== '' && item.Text3 !== undefined) {
        valany.push(parseFloat(item.Text3))
      }
      if (item.Text4 !== null && item.Text4 !== '' && item.Text4 !== undefined) {
        valany.push(parseFloat(item.Text4))
      }
      if (item.Text5 !== null && item.Text5 !== '' && item.Text5 !== undefined) {
        valany.push(parseFloat(item.Text5))
      }
      this.form.NewIQCParcss[index].Max = Math.max.apply(Math, valany)
      this.form.NewIQCParcss[index].Min = Math.min.apply(Math, valany)
      var dataine = this.$PublicJs.Calculation(item.Specification, this.form.NewIQCParcss[index].Max, this.form.NewIQCParcss[index].Min)
      this.form.NewIQCParcss[index].Determine = dataine
    },
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    async GetSdaten (row) {
      try {
        const { data: res } = await this.$http.post('/api/Laboratory/GetSdaten?Numbers=' + row)
        if (res.status !== 200) return
        if (res.response == null) {
          return this.$message.error(this.$t('All.系统暂无') + row + this.$t('All.的样品'))
        } else {
          this.form.Standard_sample = res.response
        }
      } catch (error) {
        alert(error)
      }
    },
    removeDomainbut (item) {
      this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(() => {
          if (this.prinform.Boxlist.length === 1) {
            return this.$message.error(this.$t('All.最少保留一列'))
          }
          var index = this.prinform.Boxlist.indexOf(item)
          if (index !== -1) {
            this.prinform.Boxlist.splice(index, 1)
          }
        })
        .catch(() => {
        })
    },
    IsUpload (type, filelist) {
      this.Type = type
      this.FileList = filelist
      this.isboll = true
    },
    falseUpload () {
      this.isboll = false
    },
    uphandsuccess (type, listdata, state) {
      switch (type) {
        case 0:
          this.form.Inspection_Table = state === 1 ? listdata[0].name : ''
          this.FileList0 = listdata
          break
        case 1:
          this.form.Drawing_Surface = state === 1 ? listdata[0].name : ''
          this.FileList1 = listdata
          break
        case 2:
          this.form.Acknowledgment = state === 1 ? listdata[0].name : ''
          this.FileList2 = listdata
          break
        default:
          break
      }
    },
    addDomain (item, index) {
      const contr = {
      //
        Number: item.Number,
        //
        Index: index + 1,
        //
        Specification: item.Specification,
        //
        Units_Dimensions: item.Units_Dimensions,
        //
        Text1: '',
        Text2: '',
        Text3: '',
        Text4: '',
        Text5: '',
        //
        Max: '',
        //
        Min: '',
        //
        Determine: ''
      }
      this.form.NewIQCParcss.splice(index + 1, 0, contr)
      for (let i = 0; i < this.form.NewIQCParcss.length; i++) {
        this.form.NewIQCParcss[i].Index = i
      }
    },
    removeDomain (item) {
      this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(() => {
          var index = this.form.NewIQCParcss.indexOf(item)
          if (index !== -1) {
            this.form.NewIQCParcss.splice(index, 1)
          }
          for (let i = 0; i < this.form.NewIQCParcss.length; i++) {
            this.form.NewIQCParcss[i].Index = i
          }
        })
        .catch(() => {
        })
    },
    ...mapMutations(['UserData', 'ConductIs']),
    async IQC_User (row, index) {
      if (index === 0) {
        for (let indexx = 0; indexx < row.Testlist.length; indexx++) {
          if (row.Testlist[indexx].Determinetype === 1) {
            if (row.Testlist[indexx].Determine === '' || row.Testlist[indexx].Determine === null || row.Testlist[indexx].Determine === undefined) {
              return this.$message.error(`${row.Testlist[indexx].Title}${this.$t('All.项实验室没有做出判断系统无法进行送呈只能暂存')}`)
            }
          }
        }
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        row.State = index
        const iqcformpost = {
          UserData: this.$store.state.Login,
          NewIQCuser: row
        }
        const { data: res } = await this.$http.post('/api/Newiqc/NewIQCuseradd', iqcformpost)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.UserData(null)
        this.Addtab(this.$t('All.新IQC检验单'), 'NewIQCSeleItem')
        this.RemoveTab(this.$t('All.新IQC检验单') + '-' + row.Numbers)
        this.Rendw(this.$t('All.待审'), 'Examine', 'UserJyySele')
      } catch (error) {
        loadingInstance.close()
        alert(this.$t('All.网络异常'))
      }
    },
    async IQCAdminGetAllFiles (row) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.get('/api/Newiqc/IQCAdminGetAllFiles?Number=' + row + '&Factory=' + this.$store.state.Login.Factory)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.msg)
        }
        loadingInstance.close()
        if (res.response.length > 0) {
          if (res.response.length > 0) {
            res.response.forEach(element => {
              element.url = this.$Upload + '/' + element.url
              element.Del = false
            })
          }
          for (let index = 0; index < res.response.length; index++) {
            if (res.response[index].Type === 3) {
              this.FileList3.splice(0, 0, res.response[index])
            }
          }
        }
      } catch (error) {
        this.$message.error(error)
        loadingInstance.close()
      }
    },
    async UserDataUp () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Newiqc/NewIQCuserup', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.msg)
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.Addtab(this.$t('All.新IQC检验单'), 'NewIQCSeleItem')
        this.RemoveTab(this.$t('All.新IQC检验单') + '-' + this.form.Numbers)
        this.Rendw(this.$t('All.待审'), 'Examine', 'UserJyySele')
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    async Delete () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Newiqc/NewIQCuserdel', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.msg)
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.Addtab(this.$t('All.新IQC检验单'), 'NewIQCSeleItem')
        this.RemoveTab(this.$t('All.新IQC检验单') + '-' + this.form.Numbers)
        this.Rendw(this.$t('All.待审'), 'Examine', 'UserJyySele')
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    addDomainmax (item, index) {
      const contr = {
        Index: index + 1,
        Title: item.Title,
        Standard: '',
        Tool: '',
        Frequency: 0,
        Benchmark: '',
        Determine: '',
        Remarks: ''
      }
      this.form.Testlist.splice(index + 1, 0, contr)
      for (let i = 0; i < this.form.Testlist.length; i++) {
        this.form.Testlist[i].Index = i
      }
    },
    removeDomainmax (item) {
      this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(() => {
          var index = this.form.Testlist.indexOf(item)
          if (index !== -1) {
            this.form.Testlist.splice(index, 1)
          }
          for (let i = 0; i < this.form.Testlist.length; i++) {
            this.form.Testlist[i].Index = i
          }
        })
        .catch(() => {
        })
    },
    fileopen (url) {
      this.ConductIs('')
      this.$store.state.Url = ''
      this.$nextTick(() => { // 异步关闭
        setTimeout(() => {
          // 在延迟2秒后执行的代码
          this.$store.state.Url = this.$Upload + '/' + url
          this.ConductIs('ConductIs')
        }, 500)
      })
    // window.open(url, '_blank')
    },
    addlist (item, index, value) {
      if (value === this.$t('All.增加')) {
        this.addDomain(item, index)
        this.form.NewIQCParcss[index].Determine = ''
        return
      }
      if (value === this.$t('All.删除')) {
        this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
          confirmButtonText: this.$t('All.确认'),
          cancelButtonText: this.$t('All.取消'),
          type: 'warning'
        })
          .then(() => {
            this.removeDomain(item, index)
            this.form.NewIQCParcss[index].Determine = ''
          })
          .catch(() => {
            this.form.NewIQCParcss[index].Determine = ''
          })
        return
      }
      if (value === this.$t('All.判定')) {
        const valany = []
        if (item.Text1 !== null && item.Text1 !== '' && item.Text1 !== undefined) {
          valany.push(parseFloat(item.Text1))
        }
        if (item.Text2 !== null && item.Text2 !== '' && item.Text2 !== undefined) {
          valany.push(parseFloat(item.Text2))
        }
        if (item.Text3 !== null && item.Text3 !== '' && item.Text3 !== undefined) {
          valany.push(parseFloat(item.Text3))
        }
        if (item.Text4 !== null && item.Text4 !== '' && item.Text4 !== undefined) {
          valany.push(parseFloat(item.Text4))
        }
        if (item.Text5 !== null && item.Text5 !== '' && item.Text5 !== undefined) {
          valany.push(parseFloat(item.Text5))
        }
        this.form.NewIQCParcss[index].Max = Math.max.apply(Math, valany)
        this.form.NewIQCParcss[index].Min = Math.min.apply(Math, valany)
        var dataine = this.$PublicJs.Calculation(item.Specification, this.form.NewIQCParcss[index].Max, this.form.NewIQCParcss[index].Min)
        this.form.NewIQCParcss[index].Determine = dataine
      }
    },
    Testbox (item, index) {
      this.Test_Item = item
      this.Test_Index = index
      this.Test_Supplier = this.form.Supplier
      this.Test_Product_name = this.form.Product_name
      this.Texttable = true
      this.Test_Numbers = ''
    },
    Texttablebool () {
      this.Texttable = false
    },
    Texttablefunadd (index, row) {
      this.form.Testlist[index].Remarks = row
      this.form.Testlist[index].Determine = ''
      this.Texttable = false
    },
    lookshiyan (row, index) {
      if (row.Remarks === '' || row.Remarks.length < 0) return this.$message.error(this.$t('All.请先填写送测单'))
      if (this.isdisabled) {
        this.Test_Numbers = row.Remarks
        this.Texttable = true
        return
      }
      this.$confirm(this.$t('All.查看实验单重做实验'), this.$t('All.提示'), {
        cancelButtonText: this.$t('All.查看'),
        confirmButtonText: this.$t('All.重做实验'),
        type: 'warning'
      })
        .then(() => {
          this.Testbox(row, index)
        })
        .catch(() => {
          this.Test_Numbers = row.Remarks
          this.Texttable = true
        })
    },
    chuangjcompany () {
      var inputValue = this.form.Company
      // 移除非字母字符
      inputValue = inputValue.replace(/[^A-Za-z]/g, '')
      // 转换为大写
      inputValue = inputValue.toUpperCase()
      this.form.Company = inputValue
    },
    async FileitemDrawing_number (item, type, factory) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post(`/api/File/Fileitem?item=${item}&type=${type}&factory=${factory}`)
        if (res.status !== 200) {
          loadingInstance.close()
          this.form.Drawing_Surface = ''
          this.form.Drawing_Surfaceurl = ''
          this.Msgtieleng += this.$t('IQC.无工程图面')
          return this.$message.error(item + this.$t('All.文件系统无对应图纸请维护后在进行作业'))
        }
        loadingInstance.close()
        this.form.Drawing_Surface = res.Filedata.Filename
        this.form.Drawing_Surfaceurl = res.Filedata.Fileroute
        // this.ckwjiurl = 'http://120.195.84.42:5900/Filesystem/TCWS202309140058/1694677714973.PDF'
      } catch (error) {
        loadingInstance.close()
        this.$message.error(error)
      }
    },
    async Inspection_Table_number (item, type, factory) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post(`/api/File/Fileitem?item=${item}&type=${type}&factory=${factory}`)
        if (res.status !== 200) {
          loadingInstance.close()
          this.Msgtieleng += this.$t('IQC.无检验基准')
          this.form.Inspection_Table = ''
          this.form.Inspection_Tableurl = ''
          return this.$message.error(item + this.$t('All.文件系统无对应工程图面请维护后在进行作业'))
        }
        loadingInstance.close()
        this.form.Inspection_Table = res.Filedata.Filename
        this.form.Inspection_Tableurl = res.Filedata.Fileroute
        // this.ckwjiurl = 'http://120.195.84.42:5900/Filesystem/TCWS202309140058/1694677714973.PDF'
      } catch (error) {
        loadingInstance.close()
        this.$message.error(error)
      }
    },
    async Acknowledgment_number (item, type, factory) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post(`/api/File/Fileitemcrs?item=${item}&type=${type}&factory=${factory}`)
        if (res.status !== 200) {
          loadingInstance.close()
          this.form.Acknowledgment = ''
          this.form.Acknowledgmenturl = ''
          this.Msgtieleng += this.$t('IQC.无承认书')
          return this.$message.error(item + this.$t('All.文件系统无对应承认书请维护后在进行作业'))
        }
        loadingInstance.close()
        if (res.Filedata.length === 1) {
          this.form.Acknowledgment = res.Filedata[0].Filename
          this.form.Acknowledgmenturl = res.Filedata[0].Fileroute
          return
        }
        this.filedata = res.Filedata
        this.filedialogVisible = true
        // this.ckwjiurl = 'http://120.195.84.42:5900/Filesystem/TCWS202309140058/1694677714973.PDF'
      } catch (error) {
        loadingInstance.close()
        this.$message.error(error)
      }
    },
    rowdblclick (row) {
      this.filedialogVisible = false
      this.form.Acknowledgment = row.Filename
      this.form.Acknowledgmenturl = row.Fileroute
    },
    async Gettestbaogao () {
      try {
        if (this.Mz_Numbers.trim().length === 0) {
          this.form.Testlist[this.Mz_Index].Remarks = ''
          this.form.Testlist[this.Mz_Index].Determine = ''
          this.yzdialogVisible = false
          return this.$message.error(this.$t('All.清空订单号成功'))
        }
        const { data: res } = await this.$http.get('/api/Newiqc/Gettestbaogao?Numbers=' + this.Mz_Numbers + '&Day=' + this.Mz_day)
        if (res.status !== 200) return this.$message.error(res.msg)
        this.form.Testlist[this.Mz_Index].Remarks = this.Mz_Numbers
        this.form.Testlist[this.Mz_Index].Determine = res.response.Results
        this.Mz_Numbers = ''
        this.Mz_Index = ''
        this.Mz_day = ''
        this.yzdialogVisible = false
      } catch (error) {
        alert(error)
      }
    }
  }
}
</script>
 <style lang="less" scoped>
   .wbobye{
   left:0;
   right:0;
   top:0;
   bottom:0;
   margin:auto;
   position: fixed;
   height: 100%;
   width: 100%;
   background-color: rgba(255, 255, 255);
   min-width: 903px;
 }
   .el-col {
     border: 1px solid;
     margin-right: -1px;
      margin-bottom: -1px;
     line-height: 4.5vw;
     font-size: 2vw;
     overflow: hidden;
     text-overflow: ellipsis;
     white-space:nowrap;
   }
   .grid-content {
     min-height: 36px;
   }
   .bg-purple-light{

   }
   .row-bg {
     background-color: #f9fafc;
   }
 </style>
